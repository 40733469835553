<template>

    <div class="container mb-3">
        
        <modal-new-work-order @confirm="hideAddEditModal" id="modal-new-wo" inputId="modal-new-wo" />

        <div class="title-header">
            <div class="row px-3">
                <div class="col-sm-5">
                    <h2>Radni nalozi</h2>
                </div>

                <div v-if="user.role === 'ADMIN'" class="col-sm-7">
                    <div class="ui-button">
                        <button type="button" class="btn btn-block btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#modal-new-wo"><i class="fas fa-plus-circle fa-lg me-1" /> Dodaj novi Radni Nalog</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="header-title">
        </div>
        <div class="row mt-3">

            <request-status :loading="loading" :error="error" @reload="reloadRequest" />

            <div v-if="user.role === 'ADMIN'" class="mt-3 d-flex justify-content-between">
                
                <div class="col-md-4">
                    <div v-show="workordersView == 'STATS'">
                    <ejs-daterangepicker 
                        placeholder="Period"
                        v-model="dateRange"
                        :format="dateFormat"
                        :openOnFocus='true'
                        :change='rangeChanged'
                        :firstDayOfWeek=1
                        :serverTimezoneOffset=7.0
                        />
                    </div>
                </div>

                <div class="btn-group" role="group" aria-label="Workorders View">
                    <input type="radio" class="btn-check" name="workorders-view" id="workorders-view1" value="GRID" v-model="workordersView" autocomplete="off" checked>
                    <label class="btn btn-outline-primary" for="workorders-view1"><font-awesome-icon size="2x" icon="th" /></label>

                    <input type="radio" class="btn-check" name="workorders-view" id="workorders-view2" value="STATS" @change="changedView" v-model="workordersView" autocomplete="off">
                    <label class="btn btn-outline-primary" for="workorders-view2"><font-awesome-icon size="2x" icon="bars" /></label>
                </div>
            </div>


            <template v-if="workordersView === 'GRID'">
                <div v-for="wo in workOrdersDisplay" v-bind:key="wo.id" class="col-md-4 card-box" :class="{'finished-wo': wo.real_end_date && wo.real_end_date <= todayDate}">
                    <router-link class="card" :to="{ name: 'WorkOrder', params: { woId: wo.id }}">
                    <div class="card-body">

                        <div class="code-title">
                        <span class="badge bg-secondary">{{wo.code}}</span>
                        </div>
                        <div class="code-title">
                        <span class="badge bg-info">{{wo.investor}}</span>
                        </div>
                        
                        <h5 class="card-title">{{wo.name}}</h5>
                        <p class="card-text mb-0">Početak: {{ wo.start_date ? formatDate(wo.start_date) : '/' }}</p>
                        <p v-if="wo.real_end_date" class="card-text">Kraj radova: {{ wo.end_date ? formatDate(wo.real_end_date) : '/'}}</p>
                        <p v-else class="card-text">Očekivani kraj: {{ wo.end_date ? formatDate(wo.end_date) : '/'}}</p>
                        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                    </div>
                    </router-link>

                </div>
            </template>

            <work-orders-stats ref="workOrderStats" :showInactive="showInactive" :dateRange="dateRange" v-show="workordersView == 'STATS'" />


        </div>
        <div>
            <button v-if="!showInactive" @click="showAllWorkorders" class="text-decoration-none btn btn-link p-0 d-block m-auto">Prikaži sve naloge</button>
        </div>
    </div> 
</template>

<script>
import UtilService from '@/service/UtilService.js';
import ModalNewWorkOrder from '@/components/modals/ModalNewWorkOrder.vue';
import WorkOrdersStats from '@/components/work-orders/WorkOrdersStats.vue';
import {mapState} from 'vuex'
import { Modal } from 'bootstrap';
import RequestStatus from '../RequestStatus.vue';

export default {
    components: {
        ModalNewWorkOrder,
        RequestStatus,
        WorkOrdersStats,
    },
    setup: {
        UtilService
    },

    data: function() {
        return {
            loading: false,
            error: null,
            newWorkOrderModal: null,
            workordersView: "STATS",
            dateFormat: "dd.MM.yyyy.",
            showInactive: false,

            dateRange: [
                UtilService.getMoment().add(-90, 'day').toDate(),
                UtilService.getMoment().toDate()
            ],
        }
    },

    computed: {
        ...mapState([
            'allWorkOrders',
            'user'
        ]),

        todayDate() {
            return new Date().toISOString().slice(0,10);
        },

        workOrdersDisplay() {
            let workOrders = []
            workOrders = this.allWorkOrders.filter(wo => {
                return !wo.real_end_date || wo.real_end_date > this.todayDate
            })
            if (this.showInactive) {
                let finishedWO = this.allWorkOrders.filter(wo => {
                    return wo.real_end_date && wo.real_end_date <= this.todayDate
                })

                workOrders = [...workOrders, ...finishedWO]
            }

            return workOrders
        }
    },

    created() {
        this.loadAllWordOrders();
    },

    mounted() {
        this.newWorkOrderModal = new Modal(document.getElementById('modal-new-wo'));
    },

    methods: {
        formatDate: UtilService.formatDate,

        async loadAllWordOrders() {
            this.loading = true;
            this.error = null;

            await this.$store.dispatch('loadAllWorkOrders', this.workOrderForm).catch((error) => {
                this.error = error;
            });

            this.loading = false;
        },

        reloadRequest() {
            this.loadAllWordOrders();
        },

        hideAddEditModal() {
            this.newWorkOrderModal.hide();
        },

        rangeChanged() {
            this.$refs.workOrderStats.reloadRequest(this.dateRange);
        },

        showAllWorkorders(){
            this.showInactive = true;  
        },

        changedView() {
            this.$refs.workOrderStats.reloadRequest();
        }

    }

}
</script>

<style scoped>

    .container {
        min-height: 74vh;
    }

    .card {
        height: auto;
        padding: 10px;
        margin-bottom: 10px;
        text-decoration: none;
        color: #313131;
        transition: all ease 0.4s;

    }

    .card:hover {
        background: #eee;
    }

    .finished-wo .card {
        background: #a8a8a8;
    }

    .title-header{
        padding: 1em 0;
        background: #299be4;
        color: #fff;
        margin: 10px 0px 0;
    }

    .title-header h2{
        margin: 5px 0 0;
        font-size: 1.8em;
    }

    .title-header .btn {
        color: #566787;
        float: right;
        font-size: 13px;
        background: #fff;
        border: none;
        min-width: 50px;
        border-radius: 2px;
        border: none;
        outline: none !important;
        /* margin-left: 10px; */
    }

    .title-header .btn:hover, .title-header .btn:focus {
        color: #566787;
        background: #f2f2f2;
    }

    .title-header .btn .btn-title {
        margin-top: 20px;
    }


</style>