<template>
    <div class="workorder-page">
        <Navbar />
        <Breadcrumbs></Breadcrumbs>

        <WorkOrders v-if="user.role !== 'WORKER'"/>
        <PreWorkOrders v-else />
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import WorkOrders from '@/components/work-orders/WorkOrders.vue';
import PreWorkOrders from '@/components/work-orders/PreWorkOrders.vue';
import Footer from '@/components/Footer.vue';

import {mapState} from 'vuex'

export default {

    components: {
        Navbar,
        WorkOrders,
        PreWorkOrders,
        Footer
    },
    
    computed: {
        ...mapState([
            'user'
        ]),
        
    },
}
</script>
