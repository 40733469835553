<template>
    <router-link :to="{ name: 'WorkOrder', params: { woId: data.id }}">
    <span>{{ data.name }}</span>
    </router-link>
        
</template>

<script>
export default {
    name: 'grid-row-index',

    data: function() {
        return {
            data: {},
        }
    },
}
</script>
