<template>
    <div>
        <div class="text-center mb-3">
            <div v-if="loading" class="spinner spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-if="error">
            <h2>Greška <span class="badge bg-dark status-badge">{{errorStatus}}</span></h2>
            <div v-if="errorStatus==403" class="alert alert-danger overflow-auto" role="alert">
                Nemas dozvolu za ovu opcioju
            </div>

            <div v-else class="alert alert-danger overflow-auto" role="alert">
                {{errorMessage}}
            </div>
            <a @click="realodRequest" href="#">Pokušaj ponovo</a>

        </div>

    </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Error,
            default: null,
        }
    },

    computed: {
        errorMessage() {
            if (this.error?.response) {
                return this.error?.response.data;
            } else if (this.error?.request) {
                return this.error?.request;
            } else {
                return 'Error', this.error?.message;
            }
        },

        errorStatus() {
            if (this.error?.response) {
                return this.error?.response.status;
            } else {
                return 500;
            }
        }
    },

    methods: {
        realodRequest() {
            this.$emit('reload');
        }
    },
}
</script>

<style scoped>
    .spinner{
        width: 100px;
        height: 100px;
    }

    .status-badge {
        font-size: 0.4em;
    }
</style>