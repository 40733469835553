<template>
    <div>
            <!-- <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="vehicleStats">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#statsColapse" aria-expanded="true" aria-controls="statsColapse">
                        Statistika
                    </button>
                    </h2>
                    <div id="statsColapse" class="accordion-collapse collapse" aria-labelledby="vehicleStats" data-bs-parent="#accordionExample">
                        <div class="accordion-body"> -->
                            <request-status :loading="loading" :error="error" @reload="reloadRequest" />
                            <div v-if="allWorkOrders">
                                <ejs-grid
                                    ref="workOrdersStats"
                                    :dataSource="filteredWorkOrders" 
                                    class="vehicles-stats-grid"
                                    :allowFiltering='false'
                                    :allowSorting='true'
                                    :showColumnMenu='true'
                                    :allowSelection='false'
                                    :filterSettings='filterSettings'
                                    :allowPaging='false' 
                                    :pageSettings='pageSettings'
                                    :enablePersistence='false'
                                    :allowResizing='true'
                                    allowTextWrap='true'
                                    >
                                    <e-columns>
                                        <!-- <e-column field='id' headerText='ID' width='' textAlign='Left' :isPrimaryKey='true' :visible='true' :allowFiltering="false" :showColumnMenu="false"></e-column> -->
                                        <e-column field='name' :template="workOrderLinkTemplate" headerText='Naziv' width='150' textAlign="Left"></e-column>
                                        <e-column field='start_date' headerText='Pocetak radova' width='120' textAlign="Left"></e-column>
                                        <e-column field='hours' :valueAccessor='hoursValueAccessor' headerText='Radnih sati (za naplatu)' width='138' textAlign="Right"></e-column>
                                        <e-column field='raw_hours' :valueAccessor='hoursValueAccessor' headerText='Potrošeno sati' width='138' textAlign="Right"></e-column>
                                        <e-column field='duration' :valueAccessor='daysValueAccessor' headerText='Trajanje radova' width='138' textAlign="Right"></e-column>
                                    </e-columns>

                                    <e-aggregates>
                                        <e-aggregate>
                                            <e-columns>
                                                <e-column type="Sum" field="hours"></e-column>
                                                <e-column type="Sum" field="raw_hours"></e-column>
                                                <e-column type="Sum" field="duration"></e-column>
                                            </e-columns>
                                        </e-aggregate>
                                    </e-aggregates>
                                </ejs-grid> 
                            </div>


<!--                             
                        </div>
                    </div>
                </div>
            </div>
 -->

       
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import RequestStatus from '@/components/RequestStatus.vue';
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize, Aggregate } from "@syncfusion/ej2-vue-grids";
    import WorkOrderLinkTemplate from '@/components/work-orders/WorkOrderLinkTemplate.vue';
    import UtilService from '@/service/UtilService.js';
    import WorkOrderService from '@/service/WorkOrderService.js';


    export default {
        props: ['dateRange', 'showInactive'],
        components: {
            RequestStatus,
        },
       provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize, Aggregate ]
        },
        data() {
            return {

                workOrderData: [],

                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 20},


                dateLower: UtilService.formatDatePattern(UtilService.getMoment().add(-90, 'day')),
                dateUpper: UtilService.formatDatePattern(UtilService.getMoment()),
                
                loading: false,
                error: null,
            }
        },
        

        computed: {
            ...mapState([
                'allWorkOrders',
            ]),

            
            todayDate() {
                return new Date().toISOString().slice(0,10);
            },

            filteredWorkOrders() {
                let workOrders = []
                workOrders = this.workOrderData.filter(wo => {
                    return !wo.real_end_date || wo.real_end_date > this.todayDate
                })
                if (this.showInactive) {
                    let finishedWO = this.workOrderData.filter(wo => {
                        return wo.real_end_date && wo.real_end_date <= this.todayDate
                    })

                    workOrders = [...workOrders, ...finishedWO]
                }

                return workOrders
            },

        },

        methods: {

            numberCommaAccessor(field, data) {
                const valueAccessor = data[field]
                if (isNaN(valueAccessor)) return valueAccessor;

                return valueAccessor ? this.numberWithCommas(valueAccessor) + " " + this.getSufix(field)  : '/';
            },

            daysValueAccessor(field, data) {
                const valueAccessor = data[field]
                return valueAccessor + " dana"
            },

            hoursValueAccessor(field, data) {
                const valueAccessor = data[field]
                return valueAccessor + "h"
            },

            getSufix(field) {
                if (field == 'total_distance') return 'km';
                if (field == 'fuel_liters') return 'l';
                if (field == 'fuel_total') return 'RSD';
                if (field == 'avg_kmlit100') return 'l/100km';
                if (field == 'avg_distance_price') return 'RSD';
                if (field == 'price_passenger') return 'RSD';
                return "";
            },

            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            async loadAllWorkOrders(dateRange) {
                if (!dateRange) dateRange = this.dateRange

                if (!dateRange) return
                this.loading = true;

                let param = {
                    "start_date": UtilService.dateToUTC(dateRange[0]).toISOString().slice(0,10),
                    "end_date": UtilService.dateToUTC(dateRange[1]).toISOString().slice(0,10)
                }

                try {
                    let result = await WorkOrderService.getWorkOrderStats(param)
                    this.workOrderData = result.data
                } catch (error) {
                    this.error = error
                }

                this.loading = false;

            },

            reloadRequest(dateRange) {
                this.loadAllWorkOrders(dateRange);
            },


            workOrderLinkTemplate() {
                return {
                    template: {
                        extends: WorkOrderLinkTemplate
                    }
                }
            },

            updateRange(start, end) {
                this.dateLower = start.toISOString().slice(0,10);
                this.dateUpper = end.toISOString().slice(0,10);

                this.loadAllWorkOrders();
            },

            customAggregateAveragekm100(data){
                return this.customAggregateAverage(data, 'avg_kmlit100');
            },

            customAggregateAveragekm(data) {
                return this.customAggregateAverage(data, 'avg_distance_price');
            },

            customAggregateAveragePrice_passenger(data) {
                return this.customAggregateAverage(data, 'price_passenger');
            },

            customAggregateAverage(data, field) {
                let sum = 0;
                let notNull = 0;
                if (!Array.isArray(data))
                    data = data.result;

                data.forEach(element => {
                    sum += element[field]
                    if (element[field] != null) notNull += 1;
                });

                if (notNull > 0) return sum/notNull;
            },




        },

        created() {
            this.loadAllWorkOrders(this.dateRange);
        },

    }
</script>

<style>
    .vehicles-stats-grid .e-headercontent .e-table .e-columnheader {
        height: 60px;
    }
</style>