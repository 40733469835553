<template>

    <div class="container">
        
        <div class="title-header">
            <div class="row px-3">
                <div class="col-sm-6">
                    <h2>Aktivni radni nalozi</h2>
                </div>
            </div>
        </div>

        <div class="header-title">
        </div>
        <div class="row mt-3">

        <div v-for="woe in preWorkOrderEmployeeSet" v-bind:key="woe.id" class="col-md-4 card-box">
            <router-link class="card" :to="{ name: 'WorkOrder', params: { woId: woe.work_order.id }}">
            <div class="card-body">

                <div class="code-title">
                <span class="badge bg-secondary">{{ woe.work_order.code }}</span>
                </div>
                <div class="code-title">
                <span class="badge bg-info">{{ woe.work_order.investor }}</span>
                </div>
                
                <h5 class="card-title">{{ woe.work_order.name }}</h5>
                <p class="card-text mb-0">Datum dodele: {{ formatDate(woe.date) }}</p>
            </div>
            </router-link>

        </div>
        </div>
    </div> 

</template>

<script>
import UtilService from '@/service/UtilService.js';
import EmployeeService from '@/service/EmployeesService.js';

import {mapState} from 'vuex';

export default {

    data: function() {
        return {
            preWorkOrderEmployeeSet: [],
        }
    },

    computed: {
        ...mapState([
            'user',
        ]),
    },

    setup: {
        UtilService
    },

    mounted() {
        this.loadPreWorkOrders();
    },

    methods: {
        formatDate: UtilService.formatDate,

        loadPreWorkOrders() {
            EmployeeService.getPreWorkOrdersForEmployee({id: this.user.id, date: UtilService.getMoment().format("YYYY-MM-DD")})
            .then((response) => { this.preWorkOrderEmployeeSet = response.data })
            .catch((error) => { alert(error.message); })
        }
    },

}
</script>

<style scoped>
    .card {
        height: auto;
        padding: 10px;
        margin-bottom: 10px;
        text-decoration: none;
        color: #313131;
        transition: all ease 0.4s;

    }

    .card:hover {
        background: #eee;
    }

    .title-header{
        padding: 1em 0;
        background: #299be4;
        color: #fff;
        margin: 10px 0px 0;
    }

    .title-header h2{
        margin: 5px 0 0;
        font-size: 1.8em;
    }

    .title-header .btn {
        color: #566787;
        float: right;
        font-size: 13px;
        background: #fff;
        border: none;
        min-width: 50px;
        border-radius: 2px;
        border: none;
        outline: none !important;
        /* margin-left: 10px; */
    }

    .title-header .btn:hover, .title-header .btn:focus {
        color: #566787;
        background: #f2f2f2;
    }

    .title-header .btn .btn-title {
        margin-top: 20px;
    }


</style>