<template>

        <div class="modal fade" :id="inputId" tabindex="-1" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                        Dodaj novi radni nalog
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <form v-on:submit.prevent id="new-work-order-form">
                            <div class="mb-3 form-floating" v-if="workOrder">
                                <input type="text" class="form-control" id="woId" disabled v-model="workOrder.id" placeholder="Id naloga">
                                <label for="woId">Id naloga</label>
                            </div>
                            <div class="mb-3 form-floating">
                            <input type="text" class="form-control" id="woName" v-model="workOrderForm.name" placeholder="Naziv radnog naloga" required>
                                <label for="woName">Naziv radnog naloga</label>
                                <div class="invalid-feedback">
                                    Naziv je obavezno polje.
                                </div>
                            </div>
                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="woCode" v-model="workOrderForm.code" placeholder="Šifra naloga" required>
                                <label for="woCode">Šifra naloga</label>
                                <div class="invalid-feedback">
                                    Šifra je obavezno polje.
                                </div>
                            </div>
                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="woInvestor" v-model="workOrderForm.investor" placeholder="Investitor" required>
                                <label for="woInvestor">Investitor</label>
                                <div class="invalid-feedback">
                                    Investitor je obavezno polje.
                                </div>
                            </div>
                            <div class="mb-3 form-floating">
                                <input type="date" class="form-control" id="woStartDate" v-model="workOrderForm.start_date" placeholder="Početak od" required>
                                <label for="woStartDate">Početak od</label>
                                <div class="invalid-feedback">
                                    Početak od je obavezno polje.
                                </div>
                            </div>
                            <div class="mb-3 form-floating">
                                <input type="date" class="form-control" id="woEndDate" v-model="workOrderForm.end_date" placeholder="Očekivani kraj">
                                <label for="woEndDate">Očekivani kraj</label>
                            </div>
                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="woAgreedValue" v-model="workOrderForm.agreed_value" placeholder="Dogovorena cena">
                                <label for="woAgreedValue">Dogovorena cena</label>
                            </div>
                            <hr>
                            <div class="mb-3 form-floating" v-if="workOrder">
                                <input type="date" class="form-control" id="worealEndDate" v-model="workOrderForm.real_end_date" placeholder="Kraj radova">
                                <label for="worealEndDate">Kraj radova</label>
                            </div>

                            <hr>

                            <div class="mb-3 form-floating" v-if="workOrder">
                                <input type="number" min="0" class="form-control" id="woToleranceMinutes" v-model="workOrderForm.tolerance_minutes" placeholder="Tolerancija kasnjenja (m)">
                                <label for="woToleranceMinutes">Tolerancija kasnjenja (m)</label>
                            </div>

                            <info-map ref="infoMap" :search="true" :initalWorkOrder="workOrderForm" />

                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Izlaz</button>
                        <button type="button" class="btn btn-primary" @click="createWorkOrder" >{{workOrder?'Izmeni':'Dodaj'}}</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import InfoMap from "../maps/InfoMap.vue"

export default {
    components: { InfoMap },

    props: {
        inputId: {
            type: String,
            default: "",
        },

        workOrder: {
            type: Object,
            default: null,
        }
    },

    data: function() {
        return {
            workOrderForm: {},

            modalWorkOrder: null,
        }
    },

    created() {
        this.loadData();
    },

    methods: {
        loadData() {
            if (this.workOrder) {
                this.$set(this.workOrderForm,'id', this.workOrder.id);
                this.$set(this.workOrderForm,'name', this.workOrder.name);
                this.$set(this.workOrderForm,'code', this.workOrder.code);
                this.$set(this.workOrderForm,'investor', this.workOrder.investor);
                this.$set(this.workOrderForm,'start_date', this.workOrder.start_date);
                this.$set(this.workOrderForm,'end_date', this.workOrder.end_date);
                this.$set(this.workOrderForm,'real_end_date', this.workOrder.real_end_date);
                this.$set(this.workOrderForm,'agreed_value', this.workOrder.agreed_value);
                this.$set(this.workOrderForm,'tolerance_minutes', this.workOrder.tolerance_minutes);
                this.$set(this.workOrderForm,'location_longitude', this.workOrder.location_longitude);
                this.$set(this.workOrderForm,'location_latitude', this.workOrder.location_latitude);
                this.$set(this.workOrderForm,'location_radius', this.workOrder.location_radius);
            }
        },


        createWorkOrder() {
            let form = document.getElementById("new-work-order-form");
            
            form.classList.add('was-validated');
            if (!form.checkValidity())
                return;

            if (this.workOrder) {
                this.$store.dispatch('updateWorkOrder', this.workOrderForm)
                .then(() => {
                    this.toast("Radni nalog je uspešno izmenjen.");  
                    this.$router.push({ path: '/radni-nalozi' });
                })
                .catch((error) => alert(error.message))
            }
            else {
                this.$store.dispatch('createWorkOrder', this.workOrderForm)
                .then(() => {
                    this.$store.dispatch('loadAllWorkOrders', this.workOrderForm);
                    alert("Radni nalog je uspešno dodat.");
                })
                .catch((error) => alert(error.message));    
            }

            this.$emit('confirm');
        },

        modalShow() {
            this.$refs.infoMap.updateSize();
        },

        toast(message, type) {
            this.$toasted.show(message, { 
                type: type,
                theme: "toasted-primary", 
                position: "top-center", 
                duration : 3000
            });
        }
    },

    mounted() {
        const self = this;
        let myModalEl = document.getElementById(this.inputId)
        myModalEl.addEventListener('shown.bs.modal', function () {
            self.modalShow();
            self.loadData();
            self.$refs.infoMap.setInitialCircle();
        })
    }

}
</script>

<style>

</style>